import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({on:{"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.canCreate)?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'media-create' }}},[_vm._v(" "+_vm._s(_vm.$t('Add new media'))+" ")]):_vm._e()]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'media-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [(item.tags)?_c('span',[_vm._v(_vm._s(("" + (item.tags)).replaceAll(',', ', ')))]):_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$t('N/A')))])]}},{key:"item.secure_url",fn:function(ref){
var item = ref.item;
return [_c('image-zoom',{scopedSlots:_vm._u([{key:"thumbnail",fn:function(){return [_c(VImg,{attrs:{"src":item.secure_url,"height":"40","width":"40"}})]},proxy:true},{key:"zoom",fn:function(){return [_c(VImg,{attrs:{"src":item.secure_url,"height":"300","width":"300"}})]},proxy:true}],null,true)})]}},{key:"item.copy_url",fn:function(ref){
var item = ref.item;
return [_c('media-action-copy-link',{attrs:{"value":item}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'media-update', params: { id: item.id } }}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('media-action-delete',{attrs:{"value":item,"icon":""},on:{"success":_vm.onDeleteSuccess,"error":_vm.onRequestError}})]}}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Medias') },false))}
var staticRenderFns = []

export { render, staticRenderFns }